import React from "react";
import {
  AntdProvider,
  ReactQueryProvider,
  ReactRouterProvider,
} from "./providers";

function App() {
  return (
    <div>
      <AntdProvider>
        <ReactQueryProvider>
          <ReactRouterProvider />
        </ReactQueryProvider>
      </AntdProvider>
    </div>
  );
}

export default App;
