import Axios from "axios";

const http = Axios.create({
  baseURL: BASE_URL,
  timeout: 10000,
});

http.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
  {},
);

http.interceptors.response.use(
  (resp) => {
    return resp.data;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export { http };
