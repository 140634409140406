import { useMutation } from "@tanstack/react-query";
import { http } from "../libs";

interface Request {
  task_id: number;
  rate: number;
  content: string;
}

const request = (data: Request) => {
  return http.post(`/api/task/comment/create`, data);
};

export const useCommentSubmit = ({ onSuccess }: { onSuccess: () => void }) => {
  return useMutation({
    mutationFn: request,
    onSuccess: onSuccess,
  });
};
