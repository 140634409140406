import React from "react";
import { App, Button, Form, Input, Rate } from "antd";
import { useCommentSubmit, useFilmDetails } from "../../hooks";
import ReactPlayer from "react-player";
import { useParams } from "react-router-dom";
import { useTitle } from "react-use";

export const SubjectPage = () => {
  let { id } = useParams();
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const { data } = useFilmDetails({ data: { id: parseInt(id ?? "0") } });
  const submit = useCommentSubmit({
    onSuccess: async () => {
      message.success({
        content: "Success!",
        duration: 1,
        style: {
          marginTop: "50vh",
        },
        onClose: () => {
          form.resetFields();
        },
      });
    },
  });
  useTitle(data ? (data.data?.name ?? "Error") : "loading...");
  if (!data) {
    return null;
  }
  if (!data.data) {
    return (
      <div className="w-screen h-screen flex justify-center items-center text-gray-500">
        Not Found
      </div>
    );
  }
  const { name, pic, video, desc } = data.data;
  return (
    <div>
      {!!video ? (
        <div className="aspect-video w-full">
          <ReactPlayer url={video} width="100%" height="100%" controls />
        </div>
      ) : !!pic ? (
        <img src={pic} className="aspect-auto w-full object-cover" alt={name} />
      ) : null}
      <div className="p-2.5">
        <div className="text-lg mb-1">{name}</div>
        <div className="text-sm mb-2.5 text-gray-500">{desc}</div>
        <Form
          requiredMark={false}
          form={form}
          variant="filled"
          layout="vertical"
          name="comment-form"
          onFinish={async (values) => {
            await submit.mutateAsync({
              ...values,
              task_id: parseInt(id ?? "0"),
            });
          }}
          autoComplete="off"
        >
          <Form.Item
            label="Rate"
            name="rate"
            rules={[
              {
                validator(_, value) {
                  if (!value || value === 0) {
                    return Promise.reject(new Error("Please set your rate!"));
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Rate
              id="rate"
              style={{
                fontSize: 60,
                display: "flex",
                justifyContent: "space-around",
              }}
            />
          </Form.Item>
          <Form.Item
            label="Review"
            name="content"
            rules={[{ required: true, message: "Please input your review" }]}
          >
            <Input.TextArea rows={6} id="content" />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              block
              loading={submit.isPending}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
