import React from "react";
import { StyleProvider } from "@ant-design/cssinjs";
import enUS from "antd/locale/en_US";
import { App, ConfigProvider, theme } from "antd";
import "dayjs/locale/en";

export const AntdProvider = ({ children }: React.PropsWithChildren) => {
  return (
    <StyleProvider layer>
      <ConfigProvider
        locale={enUS}
        theme={{
          algorithm: theme.defaultAlgorithm,
          token: {
            colorPrimary: "#4676bb",
            borderRadius: 0,
          },
        }}
      >
        <App>{children}</App>
      </ConfigProvider>
    </StyleProvider>
  );
};
