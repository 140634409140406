export const ErrorPage = () => {
  return (
    <div className="h-screen w-screen flex flex-col justify-center items-center gap-2">
      <h1>Oops!</h1>
      <p className="text-gray-500">
        <i>Not Found</i>
      </p>
    </div>
  );
};
