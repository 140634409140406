import { useQuery } from "@tanstack/react-query";
import { http } from "../libs";
import { Film } from "../types";

interface Request {
  id: number;
}

interface Response {
  data: Film | undefined;
  // data: {
  //   id: string
  //   data: string
  // }
  // code: number
  // message: string
}

const request = async (data: Request): Promise<Response> => {
  return http.post(`/api/task/infov2`, data);
};

export const useFilmDetails = ({ data }: { data: Request }) => {
  return useQuery({
    queryKey: ["films", data],
    queryFn: () => request(data),
  });
};
