import { createBrowserRouter, RouterProvider } from "react-router-dom";
// import { HomePage } from "../pages/home/page";
import { SubjectPage } from "../pages/subject/page";
import { ErrorPage } from "../pages/not-found/page";

const router = createBrowserRouter([
  // {
  //   path: "/",
  //   element: <HomePage />,
  // },
  {
    path: "/subject/:id",
    element: <SubjectPage />,
  },
  {
    path: "*",
    element: <ErrorPage />,
  },
]);

export const ReactRouterProvider = () => {
  return <RouterProvider router={router} />;
};
